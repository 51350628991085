import { lazy, Suspense, useEffect } from 'react';

/// Components
import Index from './jsx';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './redux/auth/AuthSelectors';
import withRouter from './services/withRouter';
/// Style
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import { fetchData } from './redux/masters/settingsSlice';

const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});

function App(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, status } = useSelector((state) => state.settings);

  useEffect(() => {
    checkAutoLogin(dispatch, navigate);
    window.scrollTo(0, 0);
    if (status === 'idle') {
      dispatch(fetchData());
    }
  }, [dispatch, navigate, status]);

  let routes = (
    <Routes>
      <Route path='/' element={<Login settingData={data} />} />
      <Route path='/login' element={<Login settingData={data} />} />
      {/* <Route path='*' element={<Login />} /> */}
    </Routes>
  );
  if (props.isAuthenticated) {
    return (
      <>
        <Suspense
          fallback={
            <div id='preloader'>
              <div className='sk-three-bounce'>
                <div className='sk-child sk-bounce1'></div>
                <div className='sk-child sk-bounce2'></div>
                <div className='sk-child sk-bounce3'></div>
              </div>
            </div>
          }>
          <Index settingData={data} />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className='vh-100'>
        <Suspense
          fallback={
            <div id='preloader'>
              <div className='sk-three-bounce'>
                <div className='sk-child sk-bounce1'></div>
                <div className='sk-child sk-bounce2'></div>
                <div className='sk-child sk-bounce3'></div>
              </div>
            </div>
          }>
          {routes}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Alert } from '../../services/AlertService';
import axiosInstance from '../../services/AxiosInstance';
import { api_routes } from '../api.routes';
const initialState = {
  status: 'idle', // idle, loading, succeeded, failed
  error: null,
  submitstatus: 'idle',
  data: [],
  dataWithUrlStatus: 'idle',
  dataWithUrls: [],
};

export const fetchData = createAsyncThunk('categories/fetchData', async () => {
  const response = await axiosInstance.get(`${api_routes.categories}`);
  return response.data.data;
});
export const fetchDataWithUrls = createAsyncThunk(
  'categories/fetchDataWithUrls',
  async () => {
    const response = await axiosInstance.get(
      `${api_routes.categories}/with-urls`
    );
    return response.data.data;
  }
);

export const addData = createAsyncThunk(
  'categories/addData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${api_routes.categories}`,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.msg);
    }
  }
);
export const editData = createAsyncThunk(
  'categories/editData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `${api_routes.categories}/${data.id}`,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.msg);
    }
  }
);
export const deleteData = createAsyncThunk(
  'categories/deleteData',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `${api_routes.categories}/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.msg);
    }
  }
);

export const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    updateData: (state, action) => {
      const id = action.payload.id;
      const data = state.data.map((item) => {
        if (item.id === id) {
          return action.payload;
        }
        return item;
      });
      state.data = data;
    },
    destroyData: (state, action) => {
      const id = action.payload;
      const data = state.data.filter((item) => item.id !== id);
      state.data = data;
    },
    clearData: (state, action) => {
      state.data = [];
      state.status = 'idle';
      state.dataWithUrlStatus = 'idle';
      state.dataWithUrls = [];
    },
  },
  extraReducers: {
    [fetchData.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchData.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchData.rejected]: (state, action) => {
      state.status = 'failed';
      console.log(action);
      state.error = action.payload;
    },
    [fetchDataWithUrls.pending]: (state, action) => {
      state.dataWithUrlStatus = 'loading';
    },
    [fetchDataWithUrls.fulfilled]: (state, action) => {
      state.dataWithUrlStatus = 'succeeded';
      state.dataWithUrls = action.payload;
    },
    [fetchDataWithUrls.rejected]: (state, action) => {
      state.dataWithUrlStatus = 'failed';
      console.log(action);
      state.error = action.payload;
    },
    [addData.pending]: (state, action) => {
      state.submitstatus = 'submitting';
    },
    [addData.fulfilled]: (state, action) => {
      state.submitstatus = 'succeeded';
      Alert('success', action.payload.msg);
      state.data.push(action.payload.data);
    },
    [addData.rejected]: (state, action) => {
      state.submitstatus = 'failed';
      state.error = action.payload;
      Alert('error', action.payload);
    },
    [editData.pending]: (state, action) => {
      state.submitstatus = 'submitting';
    },
    [editData.fulfilled]: (state, action) => {
      state.submitstatus = 'succeeded';
      Alert('success', action.payload.msg);
    },
    [editData.rejected]: (state, action) => {
      state.submitstatus = 'failed';
      state.error = action.payload;
      Alert('error', action.payload);
    },
    [deleteData.pending]: (state, action) => {
      state.submitstatus = 'submitting';
    },
    [deleteData.fulfilled]: (state, action) => {
      state.submitstatus = 'succeeded';
      Alert('success', action.payload.msg);
    },
    [deleteData.rejected]: (state, action) => {
      state.submitstatus = 'failed';
      state.error = action.payload;
      Alert('error', action.payload);
    },
  },
});

export const { updateData, destroyData, clearData } = categorySlice.actions;

export default categorySlice.reducer;

import React from 'react';
import Animation from '../components/Animation';

const NotFound = () => {
  return (
    <div>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-2'></div>
          <div className='col-sm-8 text-center'>
            <Animation type={'notfound'} />
          </div>
          <div className='col-sm-2'></div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;

import { toast } from 'react-toastify';

export const Alert = (type, payload) => {
  const options = {
    position: 'top-right',
    autoClose: 5000,
    theme: 'light',
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };
  switch (type) {
    case 'success':
      toast.success(`${payload}!`, options);
      break;
    case 'error':
      if (typeof payload === 'object') {
        payload.map((error) => {
          return toast.error(error.msg, options);
        });
      } else {
        toast.error(`${payload}!`, options);
      }
      break;
    case 'info':
      toast.info(`${payload}!`, options);
      break;
    case 'warning':
      toast.warn(`${payload}!`, options);
      break;
    default:
      toast.info(`${payload}!`, options);
      break;
  }
};

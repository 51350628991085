const prefixMaster = '';
export const api_routes = {
  assetCategories: `${prefixMaster}asset-categories`,
  assetLocations: `${prefixMaster}asset-locations`,
  auth: 'auth',
  categories: `${prefixMaster}categories`,
  departments: `${prefixMaster}departments`,
  designations: `${prefixMaster}designations`,
  employees: `${prefixMaster}employees`,
  locations: `${prefixMaster}locations`,
  peoples: `${prefixMaster}peoples`,
  peopleTypes: `${prefixMaster}people-types`,
  privileges: `${prefixMaster}privileges`,
  products: `${prefixMaster}products`,
  roles: `${prefixMaster}roles`,
  stores: `${prefixMaster}stores`,
  storeTypes: `${prefixMaster}store-types`,
  subCategories: `${prefixMaster}sub-categories`,
  users: `${prefixMaster}users`,
  settings: `${prefixMaster}settings`,
  urlDashboard: `${prefixMaster}url-dashboards`,
  roleUrl: `${prefixMaster}role-urls`,
};

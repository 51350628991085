import React from 'react';
import { BsFillTrashFill, BsPencilSquare } from 'react-icons/bs';
import { Alert } from '../../services/AlertService';

const BtnDisabled = () => {
  return (
    <>
      <button
        className='btn btn-xs '
        onClick={() => Alert('warning', 'Disabled')}>
        <BsPencilSquare />
      </button>
      <button
        className='btn btn-xs'
        onClick={() => Alert('warning', 'Disabled')}>
        <BsFillTrashFill />
      </button>
    </>
  );
};

export default BtnDisabled;

import React, { useEffect } from 'react';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { FiSettings } from 'react-icons/fi';
import {
  BiHomeAlt,
  BiCategoryAlt,
  BiUserVoice,
  BiLink,
  BiDoughnutChart,
} from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../../assets/img';
import { SOCKET_URL } from '../../config/default';
import { fetchData } from '../../redux/masters/settingsSlice';
import { isAdminAccess } from '../components/Utils';

const AdminLayout = ({ Logout, children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const settingData = useSelector((state) => state.settings.data);
  const settingStatus = useSelector((state) => state.settings.status);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (settingStatus === 'idle') {
      dispatch(fetchData());
    }
  }, [settingStatus, dispatch]);

  let path = window.location.pathname;
  let menus = [];
  const subAdminMenus = [
    {
      title: 'Home',
      url: '/dashboard',
      icon: BiHomeAlt,
    },
    {
      title: 'Dashboard',
      url: '/admin',
      icon: BiDoughnutChart,
    },
    {
      title: 'Users',
      url: '/admin/user',
      icon: HiOutlineUserGroup,
    },
    {
      title: 'Roles',
      url: '/admin/role',
      icon: BiUserVoice,
    },
  ];
  const adminMenus = [
    {
      title: 'Categories',
      url: '/admin/categories',
      icon: BiCategoryAlt,
    },
    {
      title: 'Urls',
      url: '/admin/urls',
      icon: BiLink,
    },
    {
      title: 'Settings',
      url: '/admin/settings',
      icon: FiSettings,
    },
  ];

  if (isAdminAccess() === 'ADMIN') {
    menus = [...subAdminMenus, ...adminMenus];
  } else if (isAdminAccess() === 'SUBADMIN') {
    menus = [...subAdminMenus];
  }

  return (
    <div className='container admin_dashboard'>
      <div className='row'>
        <div className='col-sm-12 logout-button-holder'>
          <button
            className='btn btn-outline-secondary btn-rounded'
            onClick={Logout}>
            Logout
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-3'>
          <div className='card'>
            <div className='card-body'>
              <div className='text-center'>
                <img
                  src={
                    settingData?.org_logo
                      ? `${SOCKET_URL}${settingData?.org_logo}`
                      : Logo
                  }
                  alt='Logo'
                  className='img img-responsive logo'
                />
              </div>

              <div className='list-group list-group-flush '>
                {menus.map((menu, index) => {
                  return (
                    <button
                      key={index}
                      onClick={() => navigate(menu.url)}
                      type='button'
                      className={`list-group-item list-group-item-action admin-menu-item ${
                        path === menu.url && 'active'
                      } `}>
                      <menu.icon />
                      {menu.title}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className='col-sm-9'>
          <div className='card'>
            <div className='card-body'>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;

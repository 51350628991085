import React from 'react';

/// React router dom
import { Route, Routes, useNavigate } from 'react-router-dom';

/// Dashboard
import Dashboard from './pages/Dashboard';

/// Pages
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

// icons
import { useDispatch } from 'react-redux';
import { logout } from '../redux/auth/AuthActions';
import Menu from './components/Menu';
import Layout from './pages/Layout';
import { isAdminAccess } from './components/Utils';
import AdminDashboard from './pages/Admin/AdminDashboard';
import AdminUser from './pages/Admin/AdminUser';
import AdminRole from './pages/Admin/AdminRole';
import AdminCategories from './pages/Admin/AdminCategories';
import AdminUrls from './pages/Admin/AdminUrls';
import AdminSettings from './pages/Admin/AdminSettings';
import AdminRoleAccess from './pages/Admin/AdminRoleAccess';
import NotFound from './pages/NotFound';

const Markup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let routes = [];
  const userRoutes = [
    /// Dashboard
    { url: '', component: Dashboard },
    { url: 'dashboard', component: Dashboard },
    { url: 'app/:id', component: Layout },
    { url: '*', component: NotFound },
  ];
  const subAdminRoutes = [
    {
      url: '/admin',
      component: AdminDashboard,
    },
    {
      url: '/admin/user',
      component: AdminUser,
    },
    {
      url: '/admin/role',
      component: AdminRole,
    },
    {
      url: '/admin/role-access/:id',
      component: AdminRoleAccess,
    },
  ];
  const adminRoutes = [
    {
      url: '/admin/categories',
      component: AdminCategories,
    },
    {
      url: '/admin/urls',
      component: AdminUrls,
    },
    {
      url: '/admin/settings',
      component: AdminSettings,
    },
  ];

  routes = [...userRoutes];
  if (isAdminAccess() === 'ADMIN') {
    routes = [...userRoutes, ...subAdminRoutes, ...adminRoutes];
  } else if (isAdminAccess() === 'SUBADMIN') {
    routes = [...userRoutes, ...subAdminRoutes];
  }

  let path = window.location.pathname;
  path = path.split('/');
  path = path[path.length - 1];

  let pagePath = path.split('-').includes('page');

  const openNav = () => {
    document.getElementById('myNav').style.width = '100%';
  };

  const closeNav = () => {
    document.getElementById('myNav').style.width = '0%';
  };

  const Logout = () => {
    dispatch(logout(navigate));
  };

  return (
    <>
      <div
        className={`${!pagePath ? 'container-fluid' : ''}`}
        style={{ minHeight: window.screen.height - 60, padding: 0 }}>
        <Routes>
          {routes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`/${data.url}`}
              element={<data.component openNav={openNav} Logout={Logout} />}
            />
          ))}
        </Routes>
      </div>

      <Menu closeNav={closeNav} Logout={Logout} />

      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        icon={true}
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Markup;

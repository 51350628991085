import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addData,
  deleteData,
  destroyData,
  editData,
  fetchData,
  updateData,
} from '../../../redux/masters/urlDashboardsSlice';
import { fetchData as fetchCategoriesData } from '../../../redux/masters/categorySlice';
import AdminLayout from '../../Layout/AdminLayout';

import { BsFillTrashFill, BsPencilSquare } from 'react-icons/bs';
import { FaExternalLinkAlt } from 'react-icons/fa';

import swal from 'sweetalert';
import { Button, Modal } from 'react-bootstrap';
import Animation from '../../components/Animation';
import { SOCKET_URL } from '../../../config/default';
import { trimText } from '../../components/Utils';

const AdminUrls = ({ Logout }) => {
  const dispatch = useDispatch();
  const { status, data } = useSelector((state) => state.urlDashboards);

  // other redux states
  const categoryData = useSelector((state) => state.categories.data);
  const categoryStatus = useSelector((state) => state.categories.status);

  const initialFormDatas = {
    title: '',
    category: '',
    url: '',
    external: false,
  };
  const [formDatas, setFormDatas] = useState(initialFormDatas);
  const [modal, setModal] = useState(false);
  const [modalAction, setModalAction] = useState('add');

  useEffect(() => {
    window.scrollTo(0, 0);
    if (status === 'idle') {
      dispatch(fetchData());
    }
    if (categoryStatus === 'idle') {
      dispatch(fetchCategoriesData());
    }
  }, [status, dispatch, categoryStatus]);

  const handleChange = (e) => {
    setFormDatas({ ...formDatas, [e.target.name]: e.target.value });
  };
  const handleFileChange = (e) => {
    setFormDatas({ ...formDatas, [e.target.name]: e.target.files[0] });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (modalAction === 'add') {
      // add
      const submissionData = new FormData();
      submissionData.append('title', formDatas.title);
      submissionData.append('category', formDatas.category);
      submissionData.append('url', formDatas.url);
      submissionData.append('external', formDatas.external);
      submissionData.append('image', formDatas.image);
      dispatch(addData(submissionData));
    } else if (modalAction === 'edit') {
      const submissionData = new FormData();
      submissionData.append('id', formDatas.id);
      submissionData.append('title', formDatas.title);
      submissionData.append('category', formDatas.category);
      submissionData.append('url', formDatas.url);
      submissionData.append('external', formDatas.external);
      if (
        formDatas.image !== null ||
        formDatas.image !== undefined ||
        formDatas.image !== ''
      )
        submissionData.append('image', formDatas.image);

      // edit
      const res = await dispatch(editData(submissionData));

      if (!res.error) dispatch(updateData(res?.payload?.data));
    }
    setFormDatas(initialFormDatas);
    setModal(false);
    setModalAction('add');
  };
  const handleEdit = (item) => {
    setModalAction('edit');
    setModal(true);
    const newItem = { ...item, category: item?.category?.id, image: null };
    setFormDatas(newItem);
  };
  const handleDelete = async (id) => {
    // delete
    const willDelete = await swal({
      title: 'Are you sure?',
      text: `Once deleted, you will not be able to recover this !`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    });

    if (willDelete) {
      const res = await dispatch(deleteData(id));
      if (!res.error) dispatch(destroyData(id));
    }
  };

  return (
    <AdminLayout Logout={Logout}>
      <div className='row card-header-admin'>
        <div className='col-sm-6 align-self-center title'>
          <h4 className='card-title'>Urls</h4>
        </div>
        <div className='col-sm-6 align-self-center button'>
          <button
            className='btn btn-outline-dark '
            onClick={() => setModal(true)}>
            Add{' '}
          </button>
        </div>
      </div>
      {status === 'failed' ? (
        <Animation type={'error'} fullanimation={false} />
      ) : (
        <div className='row'>
          {data.length > 0 ? (
            <div className='col-sm-12 mt-3'>
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Category</th>
                    <th>Url</th>
                    <th>Actions </th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {item.image !== null &&
                            item.image !== undefined &&
                            item.image !== '' ? (
                              <div
                                className='image image-icon'
                                style={{
                                  backgroundImage: `url(${SOCKET_URL}${item?.image})`,
                                }}></div>
                            ) : (
                              <div className='image image-icon'></div>
                            )}
                          </td>
                          <td>{item?.title}</td>
                          <td>{item?.category?.name}</td>
                          <td>
                            {trimText(item?.url, 25)}
                            <span>
                              {item?.external && <FaExternalLinkAlt />}
                            </span>{' '}
                          </td>

                          <td>
                            <button
                              className='btn btn-xs '
                              onClick={() => handleEdit(item)}>
                              <BsPencilSquare />
                            </button>
                            <button
                              className='btn btn-xs'
                              onClick={() => handleDelete(item?.id)}>
                              <BsFillTrashFill />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <Animation type={'noresults'} />
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <Animation type={'noresults'} fullanimation={false} />
          )}
        </div>
      )}

      {/* Popup */}
      <Modal className='fade bd-example-modal-lg ' show={modal} size='lg'>
        <form onSubmit={(e) => handleSubmit(e)} encType='multipart/form-data'>
          <Modal.Header>
            <Modal.Title>
              {modalAction === 'add'
                ? `Add `
                : modalAction === 'edit' && `Update `}
            </Modal.Title>
            <Button
              variant=''
              className='btn-close'
              onClick={() => setModal(false)}></Button>
          </Modal.Header>
          <Modal.Body>
            <div className='basic-form'>
              <div className='row '>
                <div className='form-group mb-3 col-md-3'>
                  <label>Title</label>
                  <input
                    type='text'
                    className='form-control'
                    required
                    name='title'
                    value={formDatas.title}
                    onChange={handleChange}
                    placeholder='Ex : Reports'
                  />
                </div>
                <div className='form-group mb-3 col-md-3'>
                  <label>Url</label>
                  <input
                    type='text'
                    className='form-control'
                    required
                    name='url'
                    value={formDatas.url}
                    onChange={handleChange}
                    placeholder='Ex : http://192.168.0.1:3242'
                  />
                </div>

                <div className='form-group mb-3 col-md-3'>
                  <label>Categories</label>
                  <select
                    className='form-control '
                    name='category'
                    value={formDatas.category}
                    onChange={handleChange}>
                    <option value=''>Choose...</option>
                    {categoryData.map((category, index) => {
                      return (
                        <option key={index} value={category.id}>
                          {category.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className='form-group mb-3 col-md-3'>
                  <label>External Tab</label>
                  <select
                    className='form-control '
                    name='external'
                    value={formDatas.external}
                    onChange={handleChange}>
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                  </select>
                </div>
                <div className='form-group mb-3 col-md-3'>
                  <label>
                    Image
                    {modalAction === 'edit' && (
                      <small>Leave empty if you dont want to change</small>
                    )}
                  </label>

                  <input
                    type='file'
                    className='form-control'
                    name='image'
                    accept='image/*'
                    onChange={handleFileChange}
                    placeholder='Ex : Image'
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='button'
              variant='outline-danger light'
              onClick={() => setModal(false)}>
              Close
            </Button>
            <Button
              variant=''
              type='submit'
              className='btn btn-outline-primary'>
              {modalAction === 'add'
                ? `Save `
                : modalAction === 'edit' && `Update `}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </AdminLayout>
  );
};

export default AdminUrls;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminLayout from '../../Layout/AdminLayout';
import { fetchData as fetchRoleData } from '../../../redux/masters/roleSlice';
import { fetchData as fetchCategoryData } from '../../../redux/masters/categorySlice';
import { fetchData as fetchUserData } from '../../../redux/masters/userSlice';
import { fetchData as fetchUrlDashboardsData } from '../../../redux/masters/urlDashboardsSlice';

import { FiUsers } from 'react-icons/fi';
import { BsFillDiagram3Fill } from 'react-icons/bs';
import { RiLinksLine } from 'react-icons/ri';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';

const AdminDashboard = ({ Logout }) => {
  const dispatch = useDispatch();

  // Role
  const rolesData = useSelector((state) => state.roles.data);
  const rolesStatus = useSelector((state) => state.roles.status);

  // categories
  const categoriesData = useSelector((state) => state.categories.data);
  const categoriesStatus = useSelector((state) => state.categories.status);

  // Urls
  const urlDashboardsData = useSelector((state) => state.urlDashboards.data);
  const urlDashboardsStatus = useSelector(
    (state) => state.urlDashboards.status
  );

  // Users

  const usersData = useSelector((state) => state.users.data);
  const usersStatus = useSelector((state) => state.users.status);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (rolesStatus === 'idle') {
      dispatch(fetchRoleData());
    }
    if (categoriesStatus === 'idle') {
      dispatch(fetchCategoryData());
    }
    if (urlDashboardsStatus === 'idle') {
      dispatch(fetchUrlDashboardsData());
    }
    if (usersStatus === 'idle') {
      dispatch(fetchUserData());
    }
  }, [
    dispatch,
    rolesStatus,
    categoriesStatus,
    urlDashboardsStatus,
    usersStatus,
  ]);

  return (
    <AdminLayout Logout={Logout}>
      <div className='row'>
        <div class='col-xl-3 col-sm-6'>
          <div class='card gradient-1 card-bx dashboard-card'>
            <div class='card-body d-flex align-items-center'>
              <div class='me-auto text-white'>
                <h2 class='text-white'>
                  {usersData?.length ? usersData.length : '0'}
                </h2>
                <span class='fs-18'>Users</span>
              </div>
              <FiUsers />
            </div>
          </div>
        </div>
        <div class='col-xl-3 col-sm-6'>
          <div class='card gradient-2 card-bx dashboard-card'>
            <div class='card-body d-flex align-items-center'>
              <div class='me-auto text-white'>
                <h2 class='text-white'>
                  {rolesData?.length ? rolesData.length : '0'}
                </h2>
                <span class='fs-18'>Roles</span>
              </div>
              <MdOutlineAdminPanelSettings />
            </div>
          </div>
        </div>
        <div class='col-xl-3 col-sm-6'>
          <div class='card gradient-3 card-bx dashboard-card'>
            <div class='card-body d-flex align-items-center'>
              <div class='me-auto text-white'>
                <h2 class='text-white'>
                  {categoriesData?.length ? categoriesData.length : '0'}
                </h2>
                <span class='fs-18'>Categories</span>
              </div>
              <BsFillDiagram3Fill />
            </div>
          </div>
        </div>
        <div class='col-xl-3 col-sm-6'>
          <div class='card gradient-4 card-bx dashboard-card'>
            <div class='card-body d-flex align-items-center'>
              <div class='me-auto text-white'>
                <h2 class='text-white'>
                  {urlDashboardsData?.length ? urlDashboardsData.length : '0'}
                </h2>
                <span class='fs-18'>Urls</span>
              </div>
              <RiLinksLine />
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminDashboard;

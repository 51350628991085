import React from 'react';
import { IoCloseCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import MenuList from './MenuList';
import { isAdminAccess } from './Utils';

const Menu = ({ closeNav, Logout }) => {
  const navigate = useNavigate();
  return (
    <div id='myNav' className='menu'>
      <span className='buttons-controller'>
        {isAdminAccess() && (
          <button
            className='btn btn-outline-danger btn-rounded'
            onClick={() => {
              navigate('/admin');
              closeNav();
            }}>
            Admin Settings
          </button>
        )}

        <button
          className='btn btn-outline-secondary btn-rounded'
          onClick={Logout}>
          Logout
        </button>
        <span className='closebtn' onClick={() => closeNav()}>
          <IoCloseCircle />
        </span>
      </span>
      <MenuList closeNav={closeNav} />
    </div>
  );
};

export default Menu;

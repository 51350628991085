import React, { useEffect } from 'react';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { SOCKET_URL } from '../../config/default';
import { fetchData } from '../../redux/masters/roleUrlSlice';
import Animation from './Animation';
// import { removeHttp } from './Utils';

const MenuList = ({ color = 'white', type = '', closeNav }) => {
  // Redux State
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.roleUrl);

  const handleClick = (item) => {
    const { id, url, external } = item;
    if (external) {
      window.open(url, '_blank');
      return;
    }

    // navigate(`/app/${btoa(id)}`);
    window.location.href = `/app/${btoa(id)}`;
    closeNav();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (status === 'idle') {
      dispatch(fetchData());
    }
  }, [status, dispatch]);

  return (
    <div className={`menu-container container ${type}`}>
      {data.length > 0 ? (
        data.map((da, index) => {
          return (
            <div className='category' key={index}>
              {da.urls?.length > 0 && (
                <h3 className={`category-title ${color}`}>{da.title}</h3>
              )}
              <div className='row'>
                {da.urls?.map((item, index) => {
                  return (
                    <div
                      className='col-sm-3 url-card'
                      key={index}
                      onClick={() => handleClick(item)}>
                      <div className='card'>
                        <div className='card-body'>
                          <div className='row'>
                            <div className='col-sm-3 image-container'>
                              <div
                                className='image'
                                style={{
                                  backgroundImage: `url(${SOCKET_URL}${item.image})`,
                                }}></div>
                            </div>
                            <div className='col-sm-9 content-container'>
                              <h3>{item?.title}</h3>
                              {/* <p>{removeHttp(item?.url)}</p> */}
                              {item?.external && <HiOutlineExternalLink />}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })
      ) : (
        <Animation type='noresults' />
      )}
    </div>
  );
};

export default MenuList;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  editData,
  fetchData,
  updateData,
} from '../../../redux/masters/settingsSlice';
import AdminLayout from '../../Layout/AdminLayout';

import Animation from '../../components/Animation';
import { SOCKET_URL } from '../../../config/default';

const AdminSettings = ({ Logout }) => {
  const dispatch = useDispatch();
  const { status, data } = useSelector((state) => state.settings);

  const initialFormDatas = {
    app_name: '',
    org_web: '',
    client_web: '',
    banner: null,
    org_logo: null,
    client_logo: null,
  };
  const [formDatas, setFormDatas] = useState(initialFormDatas);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (status === 'idle') {
      dispatch(fetchData());
    }
    setFormDatas(data);
  }, [status, dispatch, data]);

  const handleChange = (e) => {
    setFormDatas({ ...formDatas, [e.target.name]: e.target.value });
  };
  const handleFileChange = (e) => {
    setFormDatas({ ...formDatas, [e.target.name]: e.target.files[0] });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const submissionData = new FormData();
    submissionData.append('app_name', formDatas.app_name);
    submissionData.append('org_web', formDatas.org_web);
    submissionData.append('client_web', formDatas.client_web);
    if (
      formDatas.banner !== null ||
      formDatas.banner !== undefined ||
      formDatas.banner !== ''
    )
      submissionData.append('banner', formDatas.banner);

    if (
      formDatas.org_logo !== null ||
      formDatas.org_logo !== undefined ||
      formDatas.org_logo !== ''
    )
      submissionData.append('org_logo', formDatas.org_logo);
    if (
      formDatas.client_logo !== null ||
      formDatas.client_logo !== undefined ||
      formDatas.client_logo !== ''
    )
      submissionData.append('client_logo', formDatas.client_logo);
    if (
      formDatas.image !== null ||
      formDatas.image !== undefined ||
      formDatas.image !== ''
    )
      submissionData.append('image', formDatas.image);

    // edit
    const res = await dispatch(editData(submissionData));
    console.log(res.payload.data);

    if (!res.error) dispatch(updateData(res?.payload?.data));
    setFormDatas(res.payload.data);
  };

  return (
    <AdminLayout Logout={Logout}>
      <div className='row card-header-admin'>
        <div className='col-sm-6 align-self-center title'>
          <h4 className='card-title'>Settings</h4>
        </div>
      </div>
      {status === 'failed' ? (
        <Animation type={'error'} fullanimation={false} />
      ) : (
        <div className='row'>
          <form onSubmit={(e) => handleSubmit(e)} encType='multipart/form-data'>
            <div className='col-sm-12 mt-3'>
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Place</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td>App Name</td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        required
                        name='app_name'
                        value={formDatas.app_name}
                        onChange={handleChange}
                        placeholder='Ex : Industry 4.0'
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {formDatas.banner !== null &&
                      formDatas.banner !== undefined &&
                      formDatas.banner !== '' ? (
                        <div
                          className='image image-icon'
                          style={{
                            backgroundImage: `url(${SOCKET_URL}${formDatas?.banner})`,
                          }}></div>
                      ) : (
                        <div className='image image-icon'></div>
                      )}
                    </td>
                    <td>
                      Banner Image
                      <br />
                      <small>Leave empty if you dont want to change</small>
                    </td>
                    <td>
                      <input
                        type='file'
                        className='form-control'
                        name='banner'
                        accept='image/*'
                        onChange={handleFileChange}
                        placeholder='Ex : Image'
                      />
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>Company Website</td>
                    <td>
                      <input
                        type='url'
                        className='form-control'
                        required
                        name='org_web'
                        value={formDatas.org_web}
                        onChange={handleChange}
                        placeholder='Ex : https://embridgesolutions.in'
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {formDatas.org_logo !== null &&
                      formDatas.org_logo !== undefined &&
                      formDatas.org_logo !== '' ? (
                        <div
                          className='image image-icon'
                          style={{
                            backgroundImage: `url(${SOCKET_URL}${formDatas?.org_logo})`,
                          }}></div>
                      ) : (
                        <div className='image image-icon'></div>
                      )}
                    </td>
                    <td>
                      Company Logo
                      <br />
                      <small>Leave empty if you dont want to change</small>
                    </td>
                    <td>
                      <input
                        type='file'
                        className='form-control'
                        name='org_logo'
                        accept='image/*'
                        onChange={handleFileChange}
                        placeholder='Ex : Image'
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Client Website</td>
                    <td>
                      <input
                        type='url'
                        className='form-control'
                        required
                        name='client_web'
                        value={formDatas.client_web}
                        onChange={handleChange}
                        placeholder='Ex : https://embridgesolutions.in'
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {formDatas.client_logo !== null &&
                      formDatas.client_logo !== undefined &&
                      formDatas.client_logo !== '' ? (
                        <div
                          className='image image-icon'
                          style={{
                            backgroundImage: `url(${SOCKET_URL}${formDatas?.client_logo})`,
                          }}></div>
                      ) : (
                        <div className='image image-icon'></div>
                      )}
                    </td>
                    <td>
                      Client Logo
                      <br />
                      <small>Leave empty if you dont want to change</small>
                    </td>
                    <td>
                      <input
                        type='file'
                        className='form-control'
                        name='client_logo'
                        accept='image/*'
                        onChange={handleFileChange}
                        placeholder='Ex : Image'
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} className='text-center'>
                      <button type='submit' className='btn btn-outline-primary'>
                        Update
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </div>
      )}
    </AdminLayout>
  );
};

export default AdminSettings;

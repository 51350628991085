import jwtDecode from 'jwt-decode';

export const removeHttp = (url) => {
  return url.replace(/^https?:\/\//, '');
};
export const trimText = (text, maxlength = 15) => {
  let newText = text;
  if (text.length > maxlength) newText = text.substr(text, maxlength) + '...';
  return newText;
};
export const decodedToken = () => {
  const userDetails = localStorage.getItem('userDetails');
  const token = JSON.parse(userDetails).token;
  return jwtDecode(token);
};
export const isAdminAccess = () => {
  const res = decodedToken();
  if (res.isAdmin) {
    return 'ADMIN';
  } else if (res.roleName === 'Sub Admin') {
    return 'SUBADMIN';
  }
  return false;
};
export const isAdminNoAuth = (role) => {
  if (role === 'Sub Admin' || role === 'Admin') return true;
  return false;
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AdminLayout from '../../Layout/AdminLayout';

import Animation from '../../components/Animation';
import { useNavigate, useParams } from 'react-router-dom';
import {
  fetchSingleData,
  addData,
  emptyData,
} from '../../../redux/masters/roleUrlSlice';
import { fetchDataWithUrls } from '../../../redux/masters/categorySlice';
import { removeHttp, trimText } from '../../components/Utils';
import { HiOutlineExternalLink } from 'react-icons/hi';

const AdminRoleAccess = ({ Logout }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;

  // Redux
  const initialFormDatas = [];
  const [data, setData] = useState(initialFormDatas);
  const { singleData, singleStatus } = useSelector((state) => state.roleUrl);
  const { dataWithUrlStatus, dataWithUrls } = useSelector(
    (state) => state.categories
  );

  // Functions

  useEffect(() => {
    if (singleStatus === 'idle') {
      dispatch(emptyData());
      dispatch(fetchSingleData(id));
    }

    if (dataWithUrlStatus === 'idle') {
      dispatch(fetchDataWithUrls());
    }
    setData(singleData?.urlIds);
  }, [singleStatus, dataWithUrlStatus, dispatch, id, singleData]);

  // useEffect(() => {}, []);

  const isPresent = (id, place) => {
    // find id is present in formDatas array
    console.log(`isPresent Data ${place}`, data);
    if (data) {
      const res = data?.find((item) => item === id);
      return res !== undefined;
    }
    return false;
  };

  const handleSubmit = async () => {
    const newData = {
      role: id,
      urls: data,
    };
    const res = await dispatch(addData(newData));

    if (!res.error) navigate('/admin/role');
  };

  const handleClick = (curData) => {
    if (singleStatus === 'succeeded') {
      if (isPresent(curData.id, 'handleClick')) {
        const newFormDatas = data.filter((item) => item !== curData.id);
        setData(newFormDatas);
      } else {
        const newFormDatas = [...data, curData.id];
        setData(newFormDatas);
      }
    }
  };

  return (
    <AdminLayout Logout={Logout}>
      <div className='row card-header-admin'>
        <div className='col-sm-6 align-self-center title'>
          <h4 className='card-title'>{singleData?.roleName}</h4>
        </div>
        <div className='col-sm-6 align-self-center button'>
          <button
            className='btn btn-outline-dark '
            onClick={() => handleSubmit()}>
            Update Access
          </button>
        </div>
      </div>
      {singleStatus === 'failed' ? (
        <Animation type={'error'} fullanimation={false} />
      ) : (
        <>
          {singleStatus === 'succeeded' && (
            <div className='row'>
              <div className='col-sm-12 mt-3'>
                <div className='menu-container container roleaccess'>
                  {dataWithUrls.map((da, index) => {
                    return (
                      <div className='category' key={index}>
                        {da.urls?.length > 0 && (
                          <h3 className={`category-title black`}>{da.title}</h3>
                        )}
                        <div className='row'>
                          {da.urls?.map((item, index) => {
                            return (
                              <div
                                className={`col-sm-3 url-card ${
                                  singleStatus === 'succeeded' &&
                                  isPresent(item?.id, singleStatus)
                                    ? 'active'
                                    : ''
                                }`}
                                onClick={() => handleClick(item)}
                                key={index}>
                                <div className='card'>
                                  <div className='card-body'>
                                    <div className='row'>
                                      <div className='col-sm-12 content-container'>
                                        <h3>{item?.title}</h3>
                                        <p>
                                          {trimText(removeHttp(item?.url), 25)}
                                        </p>
                                        {item?.external && (
                                          <HiOutlineExternalLink />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </AdminLayout>
  );
};

export default AdminRoleAccess;

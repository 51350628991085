import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addData,
  deleteData,
  destroyData,
  editData,
  fetchData,
  updateData,
} from '../../../redux/masters/userSlice';
import { fetchData as fetchRoleData } from '../../../redux/masters/roleSlice';
import VerifiedBadge from '../../components/VerifiedBadge';
import AdminLayout from '../../Layout/AdminLayout';

import { BsFillTrashFill, BsPencilSquare } from 'react-icons/bs';
import BtnDisabled from '../../components/BtnDisabled';

import swal from 'sweetalert';
import { Button, Modal } from 'react-bootstrap';
import Animation from '../../components/Animation';
import { isAdminNoAuth } from '../../components/Utils';

const AdminUser = ({ Logout }) => {
  const dispatch = useDispatch();
  const { status, data } = useSelector((state) => state.users);

  // other redux states
  const rolesData = useSelector((state) => state.roles.data);
  const rolesStatus = useSelector((state) => state.roles.status);

  const initialFormDatas = {
    role: null,
    name: '',
    username: '',
    password: '',
  };
  const [formDatas, setFormDatas] = useState(initialFormDatas);
  const [modal, setModal] = useState(false);
  const [modalAction, setModalAction] = useState('add');

  useEffect(() => {
    window.scrollTo(0, 0);
    if (status === 'idle') {
      dispatch(fetchData());
    }
    if (rolesStatus === 'idle') {
      dispatch(fetchRoleData());
    }
  }, [status, dispatch, rolesStatus]);

  const handleChange = (e) => {
    setFormDatas({ ...formDatas, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (modalAction === 'add') {
      // add
      dispatch(addData(formDatas));
    } else if (modalAction === 'edit') {
      // edit
      const res = await dispatch(editData(formDatas));
      if (!res.error) dispatch(updateData(res?.payload?.data));
    }
    setFormDatas(initialFormDatas);
    setModal(false);
    setModalAction('add');
  };
  const handleEdit = (item) => {
    setModalAction('edit');
    setModal(true);
    const newItem = { ...item, role: item?.role?.id };
    setFormDatas(newItem);
  };
  const handleDelete = async (id) => {
    // delete
    const willDelete = await swal({
      title: 'Are you sure?',
      text: `Once deleted, you will not be able to recover this !`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    });

    if (willDelete) {
      const res = await dispatch(deleteData(id));
      if (!res.error) dispatch(destroyData(id));
    }
  };

  return (
    <AdminLayout Logout={Logout}>
      <div className='row card-header-admin'>
        <div className='col-sm-6 align-self-center title'>
          <h4 className='card-title'>Users</h4>
        </div>
        <div className='col-sm-6 align-self-center button'>
          <button
            className='btn btn-outline-dark '
            onClick={() => setModal(true)}>
            Add{' '}
          </button>
        </div>
      </div>
      {status === 'failed' ? (
        <Animation type={'error'} fullanimation={false} />
      ) : (
        <div className='row'>
          {data.length > 0 ? (
            <div className='col-sm-12 mt-3'>
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>User Name</th>
                    <th>Role </th>
                    <th>Actions </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.name}</td>
                        <td>
                          {item?.username}
                          {item?.isAdmin && <VerifiedBadge />}
                        </td>
                        <td>{item?.role?.name}</td>
                        <td>
                          {isAdminNoAuth(item?.role?.name) ? (
                            <BtnDisabled />
                          ) : (
                            <>
                              <>
                                <button
                                  className='btn btn-xs '
                                  onClick={() => handleEdit(item)}>
                                  <BsPencilSquare />
                                </button>
                                <button className='btn btn-xs'>
                                  <BsFillTrashFill
                                    onClick={() => handleDelete(item?.id)}
                                  />
                                </button>
                              </>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <Animation type={'noresults'} fullanimation={false} />
          )}
        </div>
      )}

      {/* Popup */}
      <Modal className='fade bd-example-modal-lg ' show={modal} size='lg'>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Modal.Header>
            <Modal.Title>
              {modalAction === 'add'
                ? `Add `
                : modalAction === 'edit' && `Update `}
            </Modal.Title>
            <Button
              variant=''
              className='btn-close'
              onClick={() => setModal(false)}></Button>
          </Modal.Header>
          <Modal.Body>
            <div className='basic-form'>
              <div className='row '>
                <div className='form-group mb-3 col-md-3'>
                  <label>Role</label>
                  <select
                    className='form-control '
                    name='role'
                    value={formDatas.role}
                    onChange={handleChange}>
                    <option value=''>Choose...</option>
                    {rolesData.map((roleData, index) => {
                      return (
                        <option value={roleData.id} key={index}>
                          {roleData.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className='form-group mb-3 col-md-3'>
                  <label>Name</label>
                  <input
                    type='text'
                    className='form-control'
                    required
                    name='name'
                    value={formDatas.name}
                    onChange={handleChange}
                    placeholder='Ex : John Doe '
                  />
                </div>
                <div className='form-group mb-3 col-md-3'>
                  <label>Username</label>
                  <input
                    type='text'
                    className='form-control'
                    required
                    name='username'
                    value={formDatas.username}
                    onChange={handleChange}
                    placeholder='Ex : jdoe '
                  />
                </div>
                <div className='form-group mb-3 col-md-3'>
                  <label>Password</label>
                  <input
                    type='text'
                    className='form-control'
                    required={modalAction === 'edit' ? false : true}
                    name='password'
                    value={formDatas.password}
                    onChange={handleChange}
                    placeholder='Ex : Jdoe@123 '
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='button'
              variant='outline-danger light'
              onClick={() => setModal(false)}>
              Close
            </Button>
            <Button
              variant=''
              type='submit'
              className='btn btn-outline-primary'>
              {modalAction === 'add'
                ? `Save `
                : modalAction === 'edit' && `Update `}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </AdminLayout>
  );
};

export default AdminUser;

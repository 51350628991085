import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaThLarge } from 'react-icons/fa';
import Animation from '../components/Animation';
import { fetchData } from '../../redux/masters/roleUrlSlice';
import { useDispatch, useSelector } from 'react-redux';

const Layout = ({ openNav }) => {
  // Redux State
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.roleUrl);

  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { id } = params;
  const [url, setUrl] = useState(null);

  //   check id is exist on datas

  useEffect(() => {
    window.scrollTo(0, 0);
    if (status === 'idle') {
      dispatch(fetchData());
    }
    if (status === 'succeeded') {
      try {
        const filteredData = data
          .map((item) => item.urls)
          .flat()
          .find((ele) => ele.id === Number(atob(id)));

        console.log('filtered data', filteredData);
        if (filteredData) {
          setUrl(filteredData.url);
        } else {
          navigate('/dashboard');
        }
      } catch (error) {
        console.log(error);
        navigate('/dashboard');
      }
    }
  }, [status, dispatch, data, id, navigate]);

  const handleLoad = (e) => {
    console.log(e);
    setIsLoading(false);
  };

  return (
    <div className='app_page'>
      {isLoading && <Animation type={'loading'} />}
      <iframe
        src={url}
        id='appwindow'
        frameBorder='0'
        width='100%'
        height={isLoading ? '0px' : '720px'}
        title='website'
        onLoad={handleLoad}></iframe>
      <div className='fab' onClick={openNav}>
        <FaThLarge />
      </div>
    </div>
  );
};

export default Layout;

import { configureStore } from '@reduxjs/toolkit';
import { AuthReducer } from './auth/AuthReducer';

import categoryReducer from './masters/categorySlice';
import roleUrlReducer from './masters/roleUrlSlice';
import userReducer from './masters/userSlice';
import settingReducer from './masters/settingsSlice';
import roleReducer from './masters/roleSlice';
import urlDashboardReducer from './masters/urlDashboardsSlice';

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    categories: categoryReducer,
    users: userReducer,
    roleUrl: roleUrlReducer,
    settings: settingReducer,
    roles: roleReducer,
    urlDashboards: urlDashboardReducer,
  },
});

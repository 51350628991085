import React from 'react';
// import { Card } from 'react-bootstrap';
import { Player } from '@lottiefiles/react-lottie-player';
import ErrorImage from '../../assets/img/lottiefiles/error.json';
import LoadingAnim from '../../assets/img/lottiefiles/loading.json';
import ForbiddenAnim from '../../assets/img/lottiefiles/forbidden.json';
import NotFoundAnim from '../../assets/img/lottiefiles/notfound.json';
import NoResultsAnim from '../../assets/img/lottiefiles/noresults.json';
import { useNavigate } from 'react-router-dom';
const Animation = ({
  type,
  text,
  subtext,
  modal = true,
  retry = false,
  fullanimation = 'animation',
}) => {
  let textData = '';
  let subTextData = '';
  let lottie = '';
  const navigate = useNavigate();

  switch (type) {
    case 'error':
      textData =
        text !== null && text !== undefined && text !== ''
          ? text
          : 'Something went wrong';
      subTextData =
        subtext !== null && subtext !== undefined && subtext !== ''
          ? subtext
          : 'Please try again later.';

      lottie = ErrorImage;

      break;
    case 'loading':
      textData =
        text !== null && text !== undefined && text !== ''
          ? text
          : 'Loading...';
      subTextData =
        subtext !== null && subtext !== undefined && subtext !== ''
          ? subtext
          : 'We are fetching... Please wait...';
      lottie = LoadingAnim;
      break;
    case 'forbidden':
      textData =
        text !== null && text !== undefined && text !== ''
          ? text
          : 'Forbidden Access ';
      subTextData =
        subtext !== null && subtext !== undefined && subtext !== ''
          ? subtext
          : 'You dont have access to this page.';
      lottie = ForbiddenAnim;
      break;
    case 'notfound':
      textData =
        text !== null && text !== undefined && text !== ''
          ? text
          : '404 Not Found ';
      subTextData =
        subtext !== null && subtext !== undefined && subtext !== ''
          ? subtext
          : 'You may not have access to this page.';
      lottie = NotFoundAnim;
      break;
    case 'noresults':
      textData =
        text !== null && text !== undefined && text !== ''
          ? text
          : 'No Results Found ';
      subTextData =
        subtext !== null && subtext !== undefined && subtext !== ''
          ? subtext
          : 'Please contact your administrator for further details.';

      if (!fullanimation) subTextData = 'Please add something here.';

      lottie = NoResultsAnim;
      break;
    default:
      break;
  }

  return (
    <div className={`card-body ${fullanimation}`}>
      <div className='row'>
        <div className='col-sm-8 offset-md-2 text-center'>
          <Player
            autoplay
            loop
            src={lottie}
            style={{ height: '300px' }}></Player>
          <h3>{textData}</h3>
          <p>{subTextData}</p>
          {retry && (
            <button className='btn btn-dark btn-xs' onClick={() => retry()}>
              Retry
            </button>
          )}
          {type === 'notfound' && (
            <button
              className='btn btn-dark btn-xs'
              onClick={() => navigate('/')}>
              Go Home
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default Animation;

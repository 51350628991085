import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchData } from '../../redux/masters/settingsSlice';
import Animation from '../components/Animation';
import MenuList from '../components/MenuList';
import { isAdminAccess } from '../components/Utils';

const Dashboard = ({ openNav, Logout }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const settingData = useSelector((state) => state.settings.data);
  const settingStatus = useSelector((state) => state.settings.status);
  const settingsError = useSelector((state) => state.settings.error);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (settingStatus === 'idle') {
      dispatch(fetchData());
    }
  }, [settingStatus, dispatch]);

  return (
    <div className='dashboard_page_container'>
      <div className='container dashboard_page'>
        <div className='row'>
          <div className='col-sm-12 logout-button-holder'>
            {isAdminAccess() && (
              <button
                className='mr-5 btn btn-outline-danger  btn-rounded '
                onClick={() => navigate('/admin')}>
                Admin Controls
              </button>
            )}
            <button
              className='btn btn-outline-secondary btn-rounded'
              onClick={Logout}>
              Logout
            </button>
          </div>
          {settingsError === null ? (
            <MenuList color='black' type='home' />
          ) : (
            <Animation type={'error '} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import axios from 'axios';
import { API_URL } from '../config/default';
import { saveTokenInLocalStorage } from './AuthService';
import jwtDecode from 'jwt-decode';
import { Alert } from './AlertService';

const getToken = () => {
  const userDetails = localStorage.getItem('userDetails');
  let token = null;
  let refreshToken = null;
  if (userDetails !== '') {
    const userDetailsObj = JSON.parse(userDetails);
    if (userDetailsObj !== null) {
      token = userDetailsObj.token;
      refreshToken = userDetailsObj.refreshToken;
    }
  }
  return { token, refreshToken };
};

const saveToken = (token, refreshToken) => {
  const decodedToken = jwtDecode(token);
  const tokenData = {
    token,
    refreshToken,
    expiresIn: decodedToken.exp,
  };
  saveTokenInLocalStorage(tokenData,'from axiosinstance');
};

const logoutToken = () => {
  // Alert('error', 'You have been logged out due to inactivity');
  alert('You have been logged out due to inactivity');
  localStorage.removeItem('userDetails');
  window.location.href = '/login';
};

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    config.baseURL = API_URL;
    const tokenDetails = getToken();
    config.headers.Authorization = `Bearer ${tokenDetails.token}`;
    config.headers['refreshToken'] = `${tokenDetails.refreshToken}`;
    return config;
  },
  function (error) {
    // Do something with request error
    console.log('[AxiosInstance.js]Error in request interceptor', error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    console.log(response.headers)
    if (response.headers['newtoken'] !== undefined && response.headers['newtoken']!=="null")  {
      console.log('new headers received',response.headers)
      saveToken(
        response.headers['newtoken'],
        response.headers['newrefreshtoken']
      );
    }
    return response;
  },
  function (error) {
    // Do something with response error

    if (error.response?.status === 401) {
      logoutToken();
    }

    if (error.message === 'Network Error') {
      Alert('error', 'Network Error');
    }

    return Promise.reject(error);
  }
);

const exportedObject = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};

export default exportedObject;
